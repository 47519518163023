<template>
  <div>
    <div v-if="this.$route.name === 'Deposit'">
      <Deposit />
    </div>
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
import Deposit from "@/views/companyApp/deposit/Deposit.vue";

export default {
  name: 'DepositParent',
  components: {
    Deposit
  }
}
</script>
