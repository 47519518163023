<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="my-2">
        <span
          class="font-weight-medium text-body-1 primary--text text--darken-1"
          >{{ $t("deposit.depositList") }}</span
        >
        <CommonAdd
          v-if="accessRight.includes('create')"
          :name="$t('deposit.deposit')"
          pathName="addDeposit"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <ListSkeleton v-if="listSkeleton" />
          <v-card-text class="text-h5 pa-0" v-else-if="depositList.data">
            <v-data-table
              :dense="true"
              class="custom-table mb-0 ma-2 row-pointer"
              :headers="headers"
              :items="depositList.data"
              :items-per-page="limit"
              :page.sync="page"
              :server-items-length="depositList.meta.total"
              style="word-break: break-word"
              :options.sync="pagination"
              @dblclick:row="redirectOnEditPage"
              item-class="py-4"
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50],
              }"
              :search="search"
              :sort-by.sync="sortByValue"
              :sort-desc.sync="sortDescValue"
              @update:sort-by="(val) => buildUrl(val, 'sortKey')"
              @update:sort-desc="(val) => buildUrl(val, 'sortDec')"
            >
              <template v-slot:top>
                <div class="text-body-2 primary--text mb-2">
                  <v-row :dense="true">
                    <v-col cols="2" class="text-end d-flex">
                      <v-autocomplete
                        hide-details="auto"
                        dense
                        v-model="selectedStatus"
                        :items="statusList"
                        item-text="name"
                        item-value="id"
                        flat
                        return-object
                        @input="buildUrl"
                        single-line
                        :label="$t('receipt.field.status')"
                      />
                    </v-col>
                    <v-col cols="1"> </v-col>
                    <v-col cols="2" class="text-end d-flex">
                      <v-text-field
                        :full-width="false"
                        :dense="true"
                        hide-details="auto"
                        v-model="search"
                        @input="buildUrl"
                        append-icon="mdi-magnify"
                        :label="$t('common.search')"
                        single-line
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-autocomplete
                        hide-details="auto"
                        dense
                        clearable
                        v-model="selectedBank"
                        :items="bankList"
                        item-text="name"
                        item-value="id"
                        :label="$t('deposit.bank')"
                        flat
                        return-object
                        hide-no-data
                        single-line
                        @input="buildUrl"
                        transition="scale-transition"
                        offset-y
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-select
                        dense
                        hide-details="auto"
                        clearable
                        v-model="selectedPaymentType"
                        :items="paymentGroupValue"
                        :label="$t('deposit.type')"
                        item-text="description"
                        item-value="id"
                        return-object
                        flat
                        @input="buildUrl"
                        single-line
                        :menu-props="{ bottom: true, offsetY: true }"
                      />
                    </v-col>
                    <v-col cols="3" class="text-end d-flex">
                      <v-menu
                        ref="startDateMenu"
                        v-model="startDateMenu"
                        :close-on-content-click="false"
                        :return-value.sync="deposit_date"
                        transition="scale-transition"
                        offset-y
                        :nudge-left="40"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            hide-details="auto"
                            dense
                            class="me-2"
                            single-line
                            v-model="formattedFromDate"
                            @click:clear="deposit_date = ''"
                            @input="buildUrl"
                            :label="$t('receipt.from')"
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="deposit_date"
                          @input="buildUrl"
                          @change="$refs.startDateMenu.save(deposit_date)"
                        >
                          <v-btn
                            small
                            class="primary"
                            @click="$refs.startDateMenu.save(todayDate())"
                            >{{ $t("route.today") }}</v-btn
                          >
                        </v-date-picker>
                      </v-menu>
                      <v-menu
                        ref="endDateMenu"
                        v-model="endDateMenu"
                        :close-on-content-click="false"
                        :return-value.sync="deposit_validity"
                        transition="scale-transition"
                        offset-y
                        :nudge-left="160"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            hide-details="auto"
                            dense
                            single-line
                            v-model="formattedToDate"
                            @click:clear="deposit_validity = ''"
                            @input="buildUrl"
                            :label="$t('receipt.to')"
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          :min="deposit_date ? deposit_date : ''"
                          @input="buildUrl"
                          v-model="deposit_validity"
                          @change="$refs.endDateMenu.save(deposit_validity)"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
                <v-divider />
              </template>
              <template v-slot:[`item.deposit_date`]="props">
                <span>{{
                  props.item.deposit_date &&
                  (profileById.date_format
                    ? formatDateDDMMYYYY(
                        new Date(props.item.deposit_date)
                          .toISOString()
                          .substring(0, 10)
                      )
                    : new Date(props.item.deposit_date)
                        .toISOString()
                        .substring(0, 10))
                }}</span>
              </template>
              <template v-slot:no-data>
                <v-card-text class="text-caption text-center">
                  {{ $t("common.noDataFound") }}</v-card-text
                >
              </template>
              <template
                v-if="depositList.data && depositList.data.length > 0"
                v-slot:[`item.status`]="props"
              >
                <v-chip :color="getColor(props.item.status)" small dark>
                  {{
                    statusList.filter((x) => x.id == props.item.status)[0].name
                  }}
                </v-chip>
              </template>
              <template
                v-if="depositList.data && depositList.data.length > 0"
                v-slot:[`footer.prepend`]
              >
                <v-row class="d-flex">
                  <v-col class="mx-auto" cols="12">
                    <span class="pl-3 pt-2"
                      >{{ $t("common.numberOfTotalRows") }}
                      {{ depositList.meta.total }}</span
                    >
                  </v-col>
                </v-row>
              </template>
              <template v-if="depositList.meta" v-slot:[`footer.page-text`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto mr-16" cols="4">
                    <v-container class="w-100">
                      <v-pagination
                        :total-visible="5"
                        v-model="page"
                        @input="buildUrl"
                        :length="depositList.meta.last_page"
                      ></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-text class="text-caption text-center" v-else>
            {{ $t("common.noDataFound") }}</v-card-text
          >
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { getAuxiliaryZtableValueByName } from "@/utils";
import ListSkeleton from "@/components/skeleton/ListSkeleton";
import { formatDateDDMMYYYY, parseDateYYYYMMDD, todayDate } from "@/utils";
import CommonAdd from "@/components/CommonAdd";

export default {
  name: "Deposit",
  components: {
    ListSkeleton,
    CommonAdd,
  },
  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      limit: 10,
      sortByValue: this.$route.query.order_by || null,
      sortDescValue: this.$route.query.sortDec ? true : null,
      pagination: {
        page: Number(this.$route.query.page) || 1,
        itemsPerPage: 10,
        sortBy: [this.$route.query.order_by || "deposit_date"],
        sortDesc: this.$route.query.order_by
          ? this.$route.query.sortDec
            ? [true]
            : [false]
          : this.$route.query.sortDec
          ? [true]
          : [false],
      },
      paymentGroupValue: [],
      selectedBank: {
        id: Number(this.$route.query.bank) || null,
      },
      selectedPaymentType: {
        id: Number(this.$route.query.type) || null,
      },
      search: this.$route.query.search || "",
      listSkeleton: false,
      startDateMenu: false,
      endDateMenu: false,
      deposit_date:
        this.$route.query.startDate ||
        new Date(new Date().setMonth(new Date().getMonth() - 6))
          .toISOString()
          .substring(0, 10),
      deposit_validity:
        this.$route.query.endDate || new Date().toISOString().substring(0, 10),
      selectedStatus: {
        id:
          this.$route.query.status === "all"
            ? null
            : this.$route.query.status === "closed"
            ? 1
            : this.$route.query.status === "cancelled"
            ? 2
            : this.$route.query.status === "open"
            ? 0
            : null,
      },
    };
  },
  computed: {
    formattedFromDate() {
      return this.profileById.date_format
        ? parseDateYYYYMMDD(this.deposit_date)
        : formatDateDDMMYYYY(this.deposit_date);
    },
    formattedToDate() {
      return this.profileById.date_format
        ? parseDateYYYYMMDD(this.deposit_validity)
        : formatDateDDMMYYYY(this.deposit_validity);
    },
    statusList() {
      return [
        { name: this.$t("deposit.field.all"), id: null },
        { name: this.$t("deposit.field.open"), id: 0 },
        { name: this.$t("deposit.field.finished"), id: 1 },
        { name: this.$t("deposit.field.canceled"), id: 2 },
      ];
    },
    params() {
      return {
        sortBy: this.pagination.sortBy,
        sortDesc: this.pagination.sortDesc,
        page: this.pagination.page,
        itemsPerPage: this.pagination.itemsPerPage,
        query: this.search,
        selectedStatus: this.selectedStatus?.id,
        selectedPaymentType: this.selectedPaymentType?.id,
        selectedBank: this.selectedBank?.id,
        deposit_date: this.deposit_date,
        deposit_validity: this.deposit_validity,
      };
    },
    ...mapGetters({
      depositList: "deposit/depositList",
      bankList: "bank/bankList",
      locale: "locale",
      accessRight: "accessRight",
      profileById: "profile/profileById",
    }),
    headers() {
      return [
        {
          text: this.$t("deposit.id"),
          align: "start",
          value: "id",
          class: "px-1",
        },
        { text: this.$t("deposit.bank"), value: "bank.name" },
        { text: this.$t("deposit.agent"), value: "employee_name" },
        { text: this.$t("deposit.type"), value: "paymentTypes.description" },
        { text: this.$t("deposit.depositDate"), value: "deposit_date" },
        {
          text: this.$t("deposit.field.status"),
          value: "status",
          sortable: false,
        },
      ];
    },
  },
  watch: {
    params: {
      handler(newVal, oldVal) {
        if (
          JSON.stringify(newVal) !== JSON.stringify(oldVal) ||
          !Object.keys(this.$route.query).length
        )
          this.paginate(false);
      },
      deep: true,
    },
    "$route.query": {
      handler(newVal, oldVal) {
        if (
          JSON.stringify(newVal) !== JSON.stringify(oldVal) ||
          !Object.keys(this.$route.query).length
        )
          this.page = Number(this.$route.query.page) || 1;

        this.sortByValue = this.$route.query.order_by || null;

        this.sortDescValue = this.$route.query.sortDec ? true : null;

        this.selectedBank = {
          id: Number(this.$route.query.bank) || null,
        };

        this.selectedPaymentType = {
          id: Number(this.$route.query.type) || null,
        };

        this.search = this.$route.query.search || "";

        this.deposit_date =
          this.$route.query.startDate ||
          new Date(new Date().setMonth(new Date().getMonth() - 6))
            .toISOString()
            .substring(0, 10);

        this.deposit_validity =
          this.$route.query.endDate ||
          new Date().toISOString().substring(0, 10);

        this.selectedStatus = {
          id:
            this.$route.query.status === "all"
              ? null
              : this.$route.query.status === "closed"
              ? 1
              : this.$route.query.status === "cancelled"
              ? 2
              : this.$route.query.status === "open"
              ? 0
              : null,
        };
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    this.listSkeleton = true;
    // set query in url
    this.$store.commit("deposit/SET_DEPOSIT_QUERY", this.$route.query);
    if (Object.keys(this.$route.query).length) {
      this.paginate(false);
    } else {
      this.pagination.page = 1;
      this.pagination.itemsPerPage = 10;
      this.pagination.sortBy = ["deposit_date"];
      this.pagination.sortDesc = [true];
    }
    this.$store.dispatch("profile/GetCompanyById");
    //  get bank and payment type
    this.$store.dispatch("bank/GetBank", { order_by: "name|asc" });
    let tableNameValue = await getAuxiliaryZtableValueByName(8);
    if (Object.keys(tableNameValue).length > 0) {
      let paymentGroupValue = tableNameValue.ztables.sort((a, b) =>
        a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
          ? 1
          : -1
      );
      this.paymentGroupValue = paymentGroupValue.filter(
        (item) =>
          item.description !== "Bank transfer" && item.description !== "העברה"
      );
    }
  },
  methods: {
    parseDateYYYYMMDD: parseDateYYYYMMDD,
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    todayDate: todayDate,
    redirectOnEditPage(event, { item }) {
      const path = "deposit/depositAction/" + item.id;
      const windowName = "depositTab_" + item.id;
      if (
        this.accessRight.includes("edit") ||
        this.accessRight.includes("show")
      ) {
        window.open(path, windowName);
      }
    },
    getColor(status) {
      if (status == 0) return "orange";
      else if (status == 1) return "green";
      else return "red";
    },
    buildUrl(val) {
      let obj = {};
      if (typeof val === "string" || typeof val === "object") {
        this.page = 1;
        obj.page = 1;
        this.params.page = 1;
      }
      obj.search = this.search;
      obj.page = this.page;
      obj.status = this.selectedStatus?.id;
      obj.type = this.selectedPaymentType?.id;
      obj.bank = this.selectedBank?.id;
      obj.startDate = this.deposit_date;
      obj.endDate = this.deposit_validity;
      obj.order_by = this.sortByValue;
      obj.sortDec = this.sortDescValue;
      obj = Object.entries(obj).reduce((acc, [key, val]) => {
        if (key === "startDate" && val === null) return acc;
        else if (key === "endDate" && val === null) return acc;
        else if (key === "order_by" && val === null) return acc;
        else if (key === "sortDec" && val === null) return acc;
        else if (key === "type" && val === null) return acc;
        else if (key === "bank" && val === null) return acc;
        else if (key === "status") {
          if (val === null) {
            return { ...acc, [key]: "all" };
          } else if (val === 1) {
            return { ...acc, [key]: "closed" };
          } else if (val === 2) {
            return { ...acc, [key]: "cancelled" };
          } else if (val === 0) {
            return { ...acc, [key]: "open" };
          } else return acc;
        } else if (key !== "status" && !val) return acc;
        return { ...acc, [key]: val };
      }, {});
      this.$router
        .push({
          name: "Deposit",
          query: {
            ...obj,
          },
        })
        .catch(() => {});
      this.$store.commit("deposit/SET_DEPOSIT_QUERY", obj);
    },
    async paginate(isSearch = false) {
      const {
        sortBy = this.pagination.sortBy,
        sortDesc = this.pagination.sortDesc,
        page = this.pagination.page,
        itemsPerPage = this.pagination.itemsPerPage,
        selectedStatus,
        selectedPaymentType,
        selectedBank,
        deposit_date,
        deposit_validity,
      } = this.params;
      // make params for where_and
      let concateWhereAnd = "";
      let whereAndVal = "|";
      // filter with invoice date
      concateWhereAnd += deposit_date ? "from_deposit_date," : "";
      concateWhereAnd += deposit_validity ? "to_deposit_date," : "";
      concateWhereAnd += selectedStatus || selectedStatus == 0 ? "status," : "";
      concateWhereAnd += selectedPaymentType ? "type," : "";
      concateWhereAnd += selectedBank ? "bank_id," : "";
      whereAndVal += deposit_date ? deposit_date + "," : "";
      whereAndVal += deposit_validity ? deposit_validity + "," : "";
      whereAndVal +=
        selectedStatus || selectedStatus == 0 ? selectedStatus + "," : "";
      whereAndVal += selectedPaymentType ? selectedPaymentType + "," : "";
      whereAndVal += selectedBank ? selectedBank + "," : "";

      // end make params for where_and
      await this.$store
        .dispatch("deposit/GetDeposit", {
          page: isSearch ? 1 : page,
          limit: itemsPerPage,
          where_and:
            concateWhereAnd.slice(0, -1) && whereAndVal.slice(0, -1)
              ? concateWhereAnd.slice(0, -1) + whereAndVal.slice(0, -1)
              : undefined,
          where_like: this.search
            ? sortBy[0]
              ? sortBy[0] + "|" + this.search
              : sortBy[1]
              ? sortBy[1] + "|" + this.search
              : sortBy[2]
              ? sortBy[2] + "|" + this.search
              : "id,employee_name,deposit_date|" + this.search
            : "id,employee_name,deposit_date|",
          order_by: sortBy?.[0]
            ? "id|" + (sortDesc?.[0] ? "desc" : "asc") + ",desc"
            : "deposit_date,employee_id,id|" +
              (sortDesc?.[0] ? "asc" : "desc") +
              ",desc",
        })
        .then(() => (this.listSkeleton = false));
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-pagination__item {
  font-size: 12px;
}
</style>
